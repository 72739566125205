<!--set-up-support-->
<template>
  <div class="container" id="setUpSupport">
    <div class="w">
      <div class="title">设备支持</div>
      <div class="tips">行业领先的智慧停车、智慧社区解决方案提供商</div>
      <div class="list-box">
        <div class="item">
          <div class="item-img-box">
            <img src="~@/assets/imgs/set-up-support_img1.png" alt="" />
          </div>
          <span class="item-text">地磁</span>
        </div>
        <div class="item">
          <div class="item-img-box">
            <img src="~@/assets/imgs/set-up-support_img2.png" alt="" />
          </div>
          <span class="item-text">中位</span>
        </div>
        <div class="item">
          <div class="item-img-box">
            <img src="~@/assets/imgs/set-up-support_img3.png" alt="" />
          </div>
          <span class="item-text">道闸</span>
        </div>
        <div class="item">
          <div class="item-img-box">
            <img src="~@/assets/imgs/set-up-support_img4.png" alt="" />
          </div>
          <span class="item-text">PDA机</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 100px 0 0 0;
  color: #333;
  background-color: #fcfcff;
  .title {
    font-size: 28px;
    text-align: center;
  }
  .tips {
    margin-top: 25px;
    font-size: 17px;
    text-align: center;
  }

  .list-box {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    .item {
      padding: 40px 40px 55px 40px;
      width: calc(25% - 14px);
      /* width: 15%; */
      text-align: center;
      box-shadow: 12px 12px 10px rgba(0, 0, 0, 0.05);
      transition: all 0.3s;
      box-sizing: border-box;
      /* .item-img-box */
      &-img-box {
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      /* .itme-text */
      &-text {
        position: relative;
        font-size: 16px;
      }
      .item-text::before {
        transition: all 0.3s;
      }
      &:hover .item-text::before {
        position: absolute;
        bottom: -20px;
        content: "";
        height: 3px;
        width: 100%;
        border-radius: 2px;
        background-color: #248eff;
      }
      &:hover {
        cursor: pointer;
        transform: translateY(-10px);
        background-color: #fff;
      }
    }
  }
}
</style>
