<!--park-transformation-->
<template>
  <div class="container" id="parkTransformtion">
    <div class="w">
      <div class="title">社会智能停车改造</div>
      <div class="tips">
        城市管理，通过城市运行、出行强度、生态环保等线上统一实时显示、分析决策、实现城市交通统管
      </div>
      <div class="card-box">
        <div class="card-item">
          <div class="card-item-img-box">
            <img src="~@/assets/imgs/park-transformation_img1@2x.png" alt="" />
          </div>
          <div class="card-item-content">
            <div class="card-item-content-title">城市智慧停车解决方案</div>
            <div class="card-item-content-text">智慧城市泊位全新改造</div>
          </div>
          <div class="card-item-bg"></div>
        </div>
        <div class="card-item">
          <div class="card-item-img-box">
            <img src="~@/assets/imgs/park-transformation_img2@2x.png" alt="" />
          </div>
          <div class="card-item-content">
            <div class="card-item-content-title">全方面服务商企停车管理</div>
            <div class="card-item-content-text">
              企业级智慧停车解决方案、商业车场智能化改造 解决方案
            </div>
          </div>
          <div class="card-item-bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 100px 0 0 0;
  color: #333;
  background-color: #fcfcff;
  .title {
    font-size: 28px;
    text-align: center;
  }
  .tips {
    margin-top: 25px;
    font-size: 17px;
    text-align: center;
  }

  .card-box {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    .card-item {
      position: relative;
      width: calc(50% - 10px);
      /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); */
      /* card-item-img-box */
      &-img-box {
        position: relative;
        height: 251px;
        z-index: 2;
        img {
          width: 100%;
        }
      }
      /* card-item-content */
      &-content {
        position: relative;
        padding: 40px;
        z-index: 2;
        /* card-item-content-title */
        &-title {
          font-size: 24px;
        }
        /* card-item-content-text */
        &-text {
          margin-top: 20px;
          font-size: 16px;
          color: #666;
        }
      }

      &-bg {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: url("~@/assets/imgs/park-transformation_img_bg@2x.png");
        background-size: 111%;
        background-position: 59% 135%;
        z-index: 1;
      }
    }
  }
}
</style>
