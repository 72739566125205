<!--about-us-->
<template>
  <div class="container" id="aboutUs">
    <div class="w">
      <div class="title">关于我们</div>
      <div class="title2">企业简介</div>
      <div class="content">
        广东江夏生态建设有限公司成立于2008年，注册资金10000万元。主营业务为停车场设计、建设、规划和运营管理。
      </div>
      <div class="title2">企业文化</div>
      <div class="img-box">
        <div class="img-item">
          <div class="img-item-content">企业文化描述</div>
          <div class="img-item-bg"></div>
        </div>
        <div class="img-item">
          <div class="img-item-content">企业文化描述</div>
          <div class="img-item-bg"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.container {
  // padding: 50px 0 60px 0;
  padding: 100px 0 60px 0;
  color: #333;
  background-color: #fcfcff;

  .title {
    margin-bottom: 58px;
    font-size: 28px;
    text-align: center;
  }

  .title2 {
    font-size: 24px;
    color: #248eff;
  }

  .content {
    margin: 22px 0;
    color: #999999;
    font-size: 18px;
    line-height: 30px;
  }

  .img-box {
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    .img-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(50% - 10px);
      height: 220px;
      text-align: center;
      background-size: 100%;

      &:nth-child(1) {
        background-image: url("~@/assets/imgs/about-us_img1.png");
      }

      &:nth-child(2) {
        background-image: url("~@/assets/imgs/about-us_img2.png");
      }

      &:hover {
        cursor: pointer;
      }

      &-content {
        position: relative;
        font-size: 24px;
        color: #fff;
        z-index: 2;
      }

      &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
    }
  }
}
</style>
