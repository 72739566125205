<!-- Footer -->
<template>
  <div class="footer">
    <div class="w" @click="open">
      <a >粤ICP备2024298343号</a>
      <span> Copyrigh © 广东江夏生态建设有限公司</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods:{
    open(){
      window.open('https://beian.miit.gov.cn/')
    },
  }
};
</script>

<style lang="scss" scoped>
.footer {
  /* background-color: rgba(0, 0, 0, 0.05); */
  line-height: 55px;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}
span {
  margin-left: 25px;
}
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}
a:hover {
  color: rgba(0, 0, 0, 0.7);
}
.w{
  cursor: pointer;
}
</style>
