<!--Home-->
<template>
  <div class="">
    <!-- banner -->
    <Banner />
    <!-- 社会开放平台 -->
    <OpenPlatform />
    <!-- 社会智能停车改造 -->
    <ParkTransformtion />
    <!-- 城市改造规划 -->
    <!-- <ReformPlanning /> -->
    <!-- 设备支持 -->
    <SetUpSupport />
    <!-- 新闻公告 -->
    <News />
    <!-- 关于我们 -->
    <AboutUs />
  </div>
</template>

<script>
import Banner from "./components/banner";
import OpenPlatform from "./components/open-platform";
import ParkTransformtion from "./components/park-transformation";
// import ReformPlanning from "./components/reform-planning";
import SetUpSupport from "./components/set-up-support";
import News from "./components/news";
import AboutUs from "./components/about-us";

export default {
  components: {
    Banner, // banner
    OpenPlatform, // 社会开放平台
    ParkTransformtion, // 社会智能停车改造
    // ReformPlanning, // 城市改造规划
    SetUpSupport, // 设备支持
    News, // 新闻公告
    AboutUs, // 关于我们
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
