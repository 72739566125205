<!--NewItem-->
<template>
  <div class="new-item" :style="newItemStyle">
    <div class="img-box" v-if="data.imgSrc">
      <img :src="data.imgSrc" alt="" />
    </div>
    <div class="content">
      <div class="content-title">{{ data.title }}</div>
      <div class="content-text">{{ data.body }}</div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
    },
  },
  computed: {
    newItemStyle() {
      return { paddingLeft: !this.data.imgSrc ? "15px" : "0px" };
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.new-item {
  padding: 15px 0;
  display: flex;
  transition: all 0.3s;
  /* .new-item:hover */
  &:hover {
    background-color: #fcfcff;
  }
  .img-box {
    margin-right: 15px;
    flex-shrink: 0;
    width: 153px;
    height: 153px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    flex: 1;
    /* .content-title */
    &-title {
      color: #333;
      font-size: 20px;
      display: -webkit-box;
      line-height: 30px;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      /* .content-title:hover */

      &:hover {
        cursor: pointer;
      }
    }

    /* .content-text */
    &-text {
      margin-top: 10px;
      color: #999;
      font-size: 14px;
      line-height: 25px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
  }
}
</style>
