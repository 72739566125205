<template>
  <div id="app">
    <Layout />
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> -->
    </div>
    <!-- <router-view/> -->
  </div>
</template>

<script>
import Layout from "./layout";
export default {
  components: { Layout },
};
</script>

<style>
</style>
