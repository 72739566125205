<!--news-->
<template>
  <div class="container w" id="news">
    <div class="title">新闻公告</div>
    <div class="news-box">
      <div class="news-left">
        <div class="news-title-box bor-b">
          <div class="news-title">新闻</div>
        </div>
        <NewItem
          v-for="(item, index) in news"
          :key="item.id"
          :data="item"
          :class="index < news.length - 1 ? 'bor-b' : ''"
        />
        <!-- <div class="more">更多 ></div> -->
      </div>
      <div class="news-right">
        <div class="news-title-box bor-b">
          <div class="news-title">公告</div>
        </div>
        <NewItem
          v-for="(item, index) in announces"
          :key="item.id"
          :data="item"
          :class="index < announces.length - 1 ? 'bor-b' : ''"
        />
        <!-- <div class="more">更多 ></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import NewItem from "@/components/home/NewItem";
export default {
  components: { NewItem },
  data() {
    return {
      // 新闻列表
      news: [
        {
          id: 1,
          title: "“祝融号”火星车开始穿越复杂地形地带",
          body: "国家航天局的消息，截至今天（7月30日），“祝融号”火星车已经在火星表面工作75个火星日，正在为“祝融号”火星车……",
          imgSrc: require("@/assets/imgs/news_img1@2x.png"),
        },
        {
          id: 2,
          title: "南京第三轮核酸采样643万人，阳性15人",
          body: "南京累计本土病例184例，无症状1例，8例重症情况平稳,南京累计本土病例184例，无症状1例，8例重症情况平稳……",
        },
        {
          id: 3,
          title: "联播+ | 我比任何时候更懂你·百年大党永恒的承诺",
          body: "“中国共产党根基在人民、血脉在人民、力量在人民。”7月1日，习近平总书记在庆祝中国共产党成立100周年大会上发表重要讲话。……",
        },
      ],
      // 公告列表
      announces: [
        {
          id: 4,
          title: "“城市车主服务”正式上线,试运行期间优惠不断……",
          body: "国家航天局的消息，截至今天（7月30日），“祝融号”火星车已经在火星表面工作75个火星日，正在为“祝融号”火星车……",
          imgSrc: require("@/assets/imgs/news_img2@2x.png"),
        },
        {
          id: 5,
          title: "南京第三轮核酸采样643万人，阳性15人",
          body: "南京累计本土病例184例，无症状1例，8例重症情况平稳,南京累计本土病例184例，无症状1例，8例重症情况平稳……",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.bor-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.container {
  padding: 100px 0 0 0;
  color: #333;
  .title {
    font-size: 28px;
    text-align: center;
  }
  .news-box {
    margin-top: 58px;
    display: flex;
    justify-content: space-between;
    .news-left,
    .news-right {
      width: calc(50% - 10px);
    }
    .news-title {
      display: inline-block;
      padding: 10px 30px;
      border-radius: 19px 0 19px 0;
      font-size: 24px;
      color: #fff;
      background-color: rgba(36, 142, 255, 1);
    }
  }
  .more {
    padding-right: 15px;
    text-align: right;
    line-height: 40px;
    color: rgba(36, 142, 255, 1);
    /* .more:hover */
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
