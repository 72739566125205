<!--banner-->
<template>
  <div class="container" id="home">
    <Carousel :data="swiperData" />
  </div>
</template>

<script>
import Carousel from "@/components/home/Carousel";
export default {
  components: { Carousel },
  data() {
    return {
      // 轮播图数据
      swiperData: [
        {
          title: "“智慧停车”助力城市新发展",
          content: "同时满足商企、市民日常停车需要",
          btnText: "全面性",
          bgSrc: require("@/assets/imgs/banner_bg1@2x.png"),
        },
        {
          title: "“智慧停车”助力城市新发展",
          content: "",
          btnText: " 服务项目",
          bgSrc: require("@/assets/imgs/banner_bg2@2x.png"),
        },
        {
          title: "助力企业管理，潜修运行",
          content: "",
          btnText: " 社会车场",
          bgSrc: require("@/assets/imgs/banner_bg3@2x.png"),
        },
        {
          title: "高新产品迭代",
          content: "",
          btnText: " 产品支持",
          bgSrc: require("@/assets/imgs/banner_bg4@2x.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
