<!--Carousel-->
<template>
  <swiper
    class="swiper"
    ref="mySwiper"
    :options="swiperOptions"
    v-if="data.length > 1"
  >
    <swiper-slide
      class="swiper-slide"
      v-for="item in data"
      :key="item.label"
      :style="{ backgroundImage: `url(${item.bgSrc})` }"
    >
      <div class="w banner">
        <div class="content-box">
          <h1 class="content-title">{{ item.title }}</h1>
          <p class="content-text">{{ item.content }}</p>
          <div class="btns">
            <button class="btn">{{ item.btnText }}</button>
            <span class="icon"></span>
          </div>
        </div>
      </div>
      <div class="banner-bg"></div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    data: Array,
  },
  data() {
    return {
      // 轮播图配置
      swiperOptions: {
        loop: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
};
</script>

<style lang="scss" scoped>
.swiper-slide {
  position: relative;
  /* background-color: rgba(0, 4, 100, 1);
  background-image: url("~@/assets/imgs/banner@2x.png"); */
  /* background-size: 100%; */
  background-size: cover;
  background-position: center bottom;
  height: 700px;
}
.banner {
  position: relative;
  height: 100%;
  z-index: 2;
  .content-box {
    position: absolute;
    top: 50%;
    color: #fff;
    transform: translateY(-50%);
    .content-title {
      padding: 0 0 15px 0;
      font-weight: 400;
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }
    .content-text {
      margin-top: 30px;
    }
    .btns {
      margin-top: 25px;
      display: flex;
      align-items: center;
      .btn {
        margin-right: 15px;
        width: 134px;
        height: 48px;
        color: #002693;
        background-image: url("~@/assets/imgs/banner_icon@2x.png");
        background-size: 100%;
      }

      .icon {
        display: inline-block;
        width: 78px;
        height: 76px;
        background-image: url("~@/assets/imgs/banner_icon1@2x.png");
        background-size: 100%;
      }
    }
  }
}
.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
</style>
