<!--open-platform-->
<template>
  <div class="container w" id="openPlatform">
    <div class="title">社会开发平台</div>
    <div class="tips">行业领先的智慧停车、智慧社区解决方案提供商 拷贝</div>
    <div class="list">
      <div class="item">
        <div class="item-content">
          <div class="item-content-tips">Parking lot</div>
          <div class="item-content-title">车场接入官网</div>
          <div class="item-content-text">
            城市企业可以通过官网认证，办理车场、设备托管建立数据同步合作。
          </div>
          <div class="item-content-btns">
            <div class="btn-box" @click="open('https://webv2.dgjx0769.com/spas-social-partner/#/')"><button>立即进入</button></div>
          </div>
        </div>
        <div class="item-bg"></div>
      </div>
      <div class="item">
        <div class="item-content">
          <div class="item-content-tips">business</div>
          <div class="item-content-title">停车增值（商家端）</div>
          <div class="item-content-text">
            为商家提供全城的车主客源，构建新的客源渠道，提升车主服务。
          </div>
          <div class="item-content-btns">
            <div class="btn-box" @click="open('https://webv2.dgjx0769.com/vass-business/#/')"><button>商家官网</button></div>
            <!-- <div class="btn-box"><button>商家移动APP</button></div> -->
          </div>
        </div>
        <div class="item-bg"></div>
      </div>
      <div class="item">
        <div class="item-content">
          <div class="item-content-tips">Owner</div>
          <div class="item-content-title">车主服务</div>
          <div class="item-content-text">
            为商家提供全城的车主客源，构建新的客源渠道，提升车主服务。
          </div>
          <div class="item-content-btns">
            <!-- <div class="btn-box"><button>车主服务APP</button></div>
            <div class="btn-box"><button>车主服务小程序</button></div> -->
          </div>
        </div>
        <div class="item-bg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods:{
    open(url){
      window.open(url)
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 100px 0 0 0;
  text-align: center;
  color: #333;
  .title {
    font-size: 28px;
  }
  .tips {
    margin-top: 25px;
    font-size: 17px;
  }
  .list {
    margin-top: 58px;
    display: flex;
    height: 570px;
    justify-content: space-between;
    .item {
      position: relative;
      padding: 40px;
      text-align: left;
      color: #fff;
      flex-basis: calc(33.3% - 15px);
      background-color: red;
      background-size: 100%;
      background-position: bottom;
      box-sizing: border-box;

      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &:nth-child(1) {
        background-image: url("~@/assets/imgs/open_platform_bg1@2x.png");
      }
      &:nth-child(2) {
        background-image: url("~@/assets/imgs/open_platform_bg2@2x.png");
      }
      &:nth-child(3) {
        background-image: url("~@/assets/imgs/open_platform_bg3@2x.png");
      }
      /* item-content */
      &-content {
        position: relative;
        z-index: 2;
        /* item-content-title */
        &-title {
          margin-top: 15px;
          font-size: 22px;
        }
        /* item-content-text */
        &-text {
          margin-top: 25px;
          font-size: 14px;
          line-height: 25px;
        }
        /* item-content-btns */
        &-btns {
          margin-top: 30px;
          height: 80px;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          font-size: 14px;
          .btn-box {
            margin-top: 10px;
            button {
              color: #fff;
              padding: 10px 21px;
              display: inline-block;
              background-color: rgba(37, 132, 234, 1);
              transition: all 0.3s;
            }
            button:hover {
              background-color: rgb(92, 170, 253, 1);
            }
          }
        }
      }
      /* item-content-bg */
      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 1;
      }
    }
  }
}
</style>
