<template>
  <div class="container" :class="{ 'scroll-active': headerScrollActive }">
    <div class="w header">
      <div class="header-left">
        <span class="logo">
          <img src="~@/assets/imgs/xiaoxlogo.png" alt="" />
        </span>
        <span>广东江夏生态建设有限公司</span>
      </div>
      <div class="header-right">
        <ul class="nav-list">
          <li v-for="(item,index) in navList" :key="index" :class="['nav-item', activeIndex==index?'active':'']"  @click="scrollTo(item.id,index)">{{item.name}}</li>
          <!-- <li class="nav-item"  @click="scrollTo('openPlatform')">服务项目</li>
          <li class="nav-item"  @click="scrollTo('parkTransformtion')">停车资源</li>
          <li class="nav-item"  @click="scrollTo('setUpSupport')">设备支持</li>
          <li class="nav-item"  @click="scrollTo('news')">新闻公告</li>
          <li class="nav-item" @click="scrollTo('aboutUs')">关于我们</li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      headerScrollActive: false, // 头部滚动激活
      activeIndex:0,
      navList:[
        {name:'首页',id:'home'},
        {name:'服务项目',id:'openPlatform'},
        {name:'停车资源',id:'parkTransformtion'},
        {name:'设备支持',id:'setUpSupport'},
        {name:'新闻公告',id:'news'},
        {name:'关于我们',id:'aboutUs'},
      ]
    };
  },
  mounted() {
    // 监听页面滚动
    document.addEventListener("scroll", () => {
      const top = document.documentElement.scrollTop || document.body.scrollTop;
      this.headerScrollActive = top >= 100;
    });
  },
  methods:{
    scrollTo(name,index){
      this.activeIndex=index
      document.getElementById(name).scrollIntoView()
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  height: 60px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s;
  z-index: 9999;
}
.container.scroll-active {
  background-color: rgba(255, 255, 255, 1);
}

.container.scroll-active .nav-item.active {
  color: red;
}

.header {
  display: flex;
  height: 100%;
  color: rgba(0, 0, 0, 0.6);
  &-left {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 20px;

    .logo {
      margin-right: 15px;
      display: inline-block;
      $w_h: 30px;
      width: $w_h;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .nav-list {
    display: flex;
    height: 100%;
    .nav-item {
      position: relative;
      flex: 1;
      margin: 0 12px;
      width: 70px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.3s;
      /* .nav-item:hover */
      &:hover {
        cursor: pointer;
      }
      /* .nav-item.active */
      &.active,
      &:hover {
        color: #fff;
      }

      /* .nav-item.active::before */
      &.active::before,
      &:hover::before {
        position: absolute;
        bottom: 0;
        content: "";
        width: 50%;
        height: 2px;
        background-color: #fff;
        transition: all 0.3s;
      }

      .container.scroll-active &.active,
      .container.scroll-active &:hover {
        color: #248eff;
      }

      .container.scroll-active &.active::before,
      .container.scroll-active &:hover::before {
        background-color: #248eff;
      }
    }
  }
}
</style>
